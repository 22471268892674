

// getting scroll
document.querySelectorAll('[data-scroller]').forEach((scroller) => {
    scroller.addEventListener('click', (e) => {
        e.preventDefault();
        try {
            const DEST_IDENTIFIER = scroller.getAttribute('data-scroller');
            const DEST = document.querySelector(DEST_IDENTIFIER); 
            if(null !== DEST) {
                DEST.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                });
            } else {
                throw new Error('Destination not found');
            }
        } catch (error) {
            console.log(error);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })            
        }
    });
});


const TO_TOP = document.querySelector('#to_top_button');
window.addEventListener('scroll', () => {
    if(window.scrollY > 150) {
        TO_TOP.classList.add('show');
    } else {
        TO_TOP.classList.remove('show');
    }
})